import React, { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDetail } from "../../../api/DetailApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";

const DetailQna = () => {
    
    const [ , setContent] = useRecoilState(Menu);
    const [ idx, ] = useRecoilState(Idx);

    const [ cp_name,    setCpName ]     = useState('');
    const [ user_name,  setUserName ]   = useState('');
    const [ email,      setEmail ]      = useState('');
    const [ phone,      setPhone ]      = useState('');
    const [ title,      setTitle ]       = useState('');
    const [ article,    setArticle ]    = useState('');

    const [ type,    setType ]    = useState(0);

    const [ files,      setFiles ]      = useState([]);
    
    //
    useEffect(() => {

        let res = null;
        if(idx) {
            
            res = AxiosDetail('question', idx);
            res.then(item => {
                if(item.data.success) {

                    const data = item.data.data;
                    
                    setCpName(data.detail.company_name);
                    setUserName(data.detail.name);
                    setEmail(data.detail.email);
                    setPhone(data.detail.phone);
                    setTitle(data.detail.title);
                    setArticle(data.detail.content);

                    setType(data.detail.type);

                    setFiles(data.files);

                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            })
        }

    }, [idx]);

    return (
        <main id="main" className="inquire-detail">

            <div className="container">

                <div className="title-wrap">
                    <span className="title">1:1 문의 관리</span>
                </div>

                <div className="contents-wrap">
                    <div className="add-container">

                        <div className="user-info-wrap">
                            <div className="add-box">
                                <span className="title">회사명</span>
                                <p className="user-info">
                                    {type == 1 ? cp_name : ''}
                                </p>
                            </div>
                            <div className="add-box">
                                <span className="title">회원명</span>
                                <p className="user-info">
                                    {type == 0 ? user_name : ''}
                                </p>
                            </div>
                            <div className="add-box">
                                <span className="title">이메일</span>
                                <p className="user-info">{email}</p>
                            </div>
                            <div className="add-box">
                                <span className="title">연락처</span>
                                <p className="user-info">{phone}</p>
                            </div>
                        </div>

                        <div className="add-box">
                            <span className="title">제목</span>
                            <div className="input-wrap">
                                <input 
                                    type="text" 
                                    placeholder="제목을 입력하세요" 
                                    defaultValue={title}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="add-box editor-box">
                            <span className="title">내용</span>
                            <div className="input-wrap show">
                                <span dangerouslySetInnerHTML={{ __html: article }}>
                                </span>
                            </div>
                        </div>

                        {/* files  */}
                        <div className="add-box download-box">
                            <span className="title">첨부파일</span>
                            <ul className="asfile_ul">
                                {
                                    files ?
                                        files.map((item, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <div className="file_name">
                                                        <div className="left-info">
                                                            <div className="img-wrap">
                                                                <img src="./images/icon-clip.png" alt="" />
                                                            </div>
                                                            <span>{item.file_name}</span>
                                                        </div>
                                                        <div className="right-info">
                                                            <a href={item.file_addr} download>
                                                                <button>다운로드 <i className="xi-download"></i></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })

                                    :
                                    ''
                                }
                            </ul>
                        </div>

                        <div className="btn-wrap">
                            <button 
                                className="save-btn"
                                onClick={() => setContent('Qna')}
                            >
                                확인
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default DetailQna;