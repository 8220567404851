import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { AxiosDelete } from "../../../api/DeleteApi";
import { 
    axiosBanner, 
    AxiosList, 
    AxiosSearch
} from "../../../api/ListApi";

import Menu from "../../../recoil/Menu";
import Idx from "../../../recoil/Idx";

import Pagination from "../../components/Pagination";
import PaginateNumber from "../../../recoil/PaginateNumber";

const Banner = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('banner');
        } else {
            data = AxiosList('banner?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, []);

    //
    const detailOnClickHandle = (idx) => {
        setIdx(idx);
        setContent('DetailBanner');
    };

    //
    const removeOnClickHandle = (idx) => {

        if(!window.confirm("정말로 삭제하시겠습니까?")) return;

        const data = AxiosDelete('banner', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 삭제되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
            }
        });

    };

    //
    const register = () => {
        setIdx(null);
        setContent('DetailBanner');
    };

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };


    return (

        <main id="main" className="banner-set">
            <div className="container">
                <div className="title-wrap">
                    <span className="title">배너 관리</span>
                    <a onClick={register}>
                        <div className="add-btn">
                            <span>등록</span>
                        </div>
                    </a> 
                </div>

                <div className="contents-wrap">

                    <div className="list-wrap">

                        <ul className="popup-list">

                            {/* map list */}
                            {
                                list ?
                                    list.map(item => {
                                        return (
                                            <li key={item.id}>
                                                <div className="popup-img">
                                                    <img src={item.file_addr} alt="" />
                                                </div>
                                                <div className="popup-contents">
                                                    <p className="title">
                                                        {item.title}
                                                    </p>
                                                    <a 
                                                        href={item.link}
                                                        className="link"
                                                    >
                                                        {item.link}
                                                    </a>
                                                    <div className="event-control">
                                                        <a
                                                            className="re-btn"
                                                            onClick={() => detailOnClickHandle(item.id)}
                                                        >
                                                            수정
                                                        </a>
                                                        <button 
                                                            className="delete-btn"
                                                            onClick={() => removeOnClickHandle(item.id)}
                                                        >
                                                            삭제
                                                        </button>
                                                    </div> 
                                                </div>
                                            </li>
                                        );
                                    })

                                :
                                null
                            }

                        </ul>
                    </div>

                    <Pagination page={page} returnPaginateData={paginateListHandle}/>

                </div>
            </div>
        </main>

    );

}


export default Banner;