import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const Menu = atom({
    key: 'menu',
    default: 'Banners',
    effects_UNSTABLE: [persistAtom],
})

export default Menu;