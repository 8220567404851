import { DetailAxios } from "./CommonAxios";


export const AxiosDetail = (type, id) => {

    let formData = new FormData();

    formData.append('index', Number(id));
    
    return DetailAxios(type, formData);

}
