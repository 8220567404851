import React from "react";
import { useRecoilState } from "recoil";
import Menu from '../../recoil/Menu';

import LOGOB from '../../images/LOGO-b.png';
import PaginateNumber from "../../recoil/PaginateNumber";
import { useRef } from "react";

const Header = () => {  

    const [ page, setPages ] = useRecoilState(Menu);
    const [ , setPaginateNumber ] = useRecoilState(PaginateNumber);

    const useRefLi = useRef([]);

    const menuOnClickHandle = (type) => {

        setPaginateNumber(null);
        setPages(type);

    }

    const main = () => {
        setPages('Banners');
    }

    const logoutOnclickHandle = () => {
        window.localStorage.clear();
        window.location.href = '/';
    }

    // menu 는 Main.jsx 에서 호출될 컴포넌트 명칭입니다.
    const menu = [
        { menu : 'Banners',       title : '배너 관리', },
        { menu : 'Users',         title : '개인 회원 관리', },
        { menu : 'Business',      title : '기업 회원 관리', },
        { menu : 'Leaved',        title : '탈퇴 회원', },
        { menu : 'Talk',          title : '꼬모톡 내역', },
        { menu : 'Report',        title : '신고된 상품 관리', },
        { menu : 'Transaction',   title : '거래내역 관리', },
        { menu : 'Qna',           title : '1:1 문의 관리', },
        { menu : 'Notice',        title : '공지사항 관리', },
    ];
 
    return(
        <div className="menu">

            <a onClick={main} className="logo-wrap">
                <img src={LOGOB} alt="commotree" />
            </a>

            <ul className="nav-list">

                {
                    menu.map((list, idx) => {
                        return (
                            <li 
                                className={ page == list.menu ? 'select' : ''}
                                ref={(e) => useRefLi.current[idx] = e}
                                key={idx}
                            >
                                <a onClick={() => menuOnClickHandle(list.menu)}>
                                    <div className="menu-wrap">
                                        <span>{list.title}</span>
                                    </div>
                                </a>
                            </li>
                        );
                    })
                }

            </ul>
            <div className="header-bottom">
                <span className="copyright">
                    Ⓒ Commotree All rights reserved.
                </span>
                <button 
                    className="logout-btn"
                    onClick={logoutOnclickHandle}
                >
                    <i className="xi-log-out"></i>
                    <span>로그아웃</span>
                </button>
            </div>
        </div>

    );

};

export default Header;