import React from "react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDelete } from "../../../api/DeleteApi";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import Pagination from "../../components/Pagination";


const Qna = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ filter, setFilter ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('question');
        } else {
            data = AxiosList('question?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    // search
    useEffect(() => {
        search()
    }, [keyword])

    // 검색어 입력
    const searchOnChangeHandle = (e) => {
        setKeyword(e.target.value);
    };

    // 검색
    const search = () => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/question';

            const data = AxiosSearch(url, keyword);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 550);

        setTimer(callApiTimer);

    };

    // 페이지네이션
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };  

    // 삭제
    const qnaRemove = (e, idx) => {

        e.stopPropagation();

        if(!window.confirm("정말로 삭제하시겠습니까?")) return;
        
        const data = AxiosDelete('question', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 삭제되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
            }
        });

    };

    // 상세보기
    const detailOnClickHandle = (e, idx) => {

        e.preventDefault();

        setIdx(idx);
        setContent('DetailQna');
    };

    return(
        <main id="main" className="list-set inquire-set">

            <div className="container">
                <div className="title-wrap">

                    <span className="title">1:1 문의 관리</span>

                    <div className="right-wrap">
                        <div className="search-wrap">
                            <div className="search">
                                <input 
                                    type="text" 
                                    placeholder="회사명, 회원명, 제목으로 검색" 
                                    onChange={(e) => searchOnChangeHandle(e)}
                                />
                                <i className="xi-search"></i>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="contents-wrap">
                    <div className="table-container">

                        <div className="table-wrap">

                            <table className="management-table">

                                <colgroup>
                                    <col width="11%" />
                                    <col width="9%"  />
                                    <col width="10%" />
                                    <col width="17%" />
                                    <col width="37%" /> 
                                    <col width="11%" />
                                    <col width="7%"  />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>아이디</th>
                                        <th>회사명(기업)</th>
                                        <th>회원명(개인)</th>
                                        <th>이메일</th>
                                        <th>제목</th>
                                        <th>작성일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {
                                        list ? 
                                            list.map((item, idx) => {
                                                // type : 0, company_name = null or 1, name = null
                                                return (
                                                    <tr 
                                                        key={idx}
                                                        onClick={(e) => detailOnClickHandle(e, item.id)}
                                                    >
                                                        <td>
                                                            {item.check_id}
                                                        </td>

                                                        <td>
                                                            {item.type == 1 ? item.company_name : ''}
                                                        </td>

                                                        <td>
                                                            {item.type == 0 ? item.name : ''}    
                                                        </td>

                                                        <td>
                                                            {item.email}
                                                        </td>
                                                        <td>
                                                            {item.title}
                                                        </td>
                                                        <td>
                                                            {item.write_date}
                                                        </td>
                                                        <td>
                                                            <a 
                                                                className="btn-del"
                                                                onClick={(e) => qnaRemove(e, item.id)}
                                                            >
                                                                삭제
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                            ''
                                    }

                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination 
                        page={page} 
                        returnPaginateData={paginateListHandle}
                    />
                </div>
            </div>
        </main>
    );
};

export default Qna;