import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AxiosDelete } from "../../../api/DeleteApi";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";

import Pagination from "../../components/Pagination";

const Users = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('client');
        } else {
            data = AxiosList('client?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    //
    const searchOnChangeHandle = (e) => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/client';
            const keyword = e.target.value;

            const data = AxiosSearch(url, keyword);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 450);

        setTimer(callApiTimer);


    }

    //
    const detailOnClickHandle = (idx) => {
        setIdx(idx);
        setContent('DetailUser');
    };

    //
    const userleavedOnClickHandle = (idx) => {

        if(!window.confirm("정말로 삭제하시겠습니까?")) return;
        
        const data = AxiosDelete('client', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 삭제되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
            }
        });

    }

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };


    return (
        <main id="main" className="member-set individual ">
            <header id="menu"></header>
            <div className="container">
                <div className="title-wrap">
                    <span className="title">개인 회원 관리</span>
                    <div className="search">
                        <input 
                            type="text" 
                            placeholder="회원명, 연락처로 검색" 
                            onChange={(e) => searchOnChangeHandle(e)}
                        />
                        <i className="xi-search"></i>
                    </div>
                </div>
                <div className="contents-wrap">
                    <div className="table-container">
                        <div className="table-wrap">
                            <table className="management-table">
                                <colgroup>
                                    <col width="13%" />
                                    <col width="10%"  />
                                    <col width="15%"  />
                                    <col width="13%"  />
                                    <col width="20%"  /> 
                                    <col width="7%"  />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>아이디</th>
                                        <th>이름</th>
                                        <th>연락처</th>
                                        <th>생년월일</th>
                                        <th>이메일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        list.length > 0 ?
                                            list.map((user, idx) => {
                                                return(
                                                    <tr className="searchTr" key={idx}>
                                                        <td>
                                                            {user.check_id}
                                                        </td>
                                                        <td>
                                                            {user.name}
                                                        </td>
                                                        <td>
                                                            {user.phone}
                                                        </td>
                                                        <td>
                                                            {user.birth}
                                                        </td>
                                                        <td>
                                                            {user.email}
                                                        </td>
                                                        <td>
                                                            <a 
                                                                className="btn-del"
                                                                onClick={() => detailOnClickHandle(user.id)}
                                                            >
                                                                상세
                                                            </a>

                                                            <button 
                                                                className="btn-del"
                                                                onClick={() => userleavedOnClickHandle(user.id)}
                                                            >
                                                                탈퇴
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td></td>
                                            </tr>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>

                        <Pagination 
                            page={page} 
                            returnPaginateData={paginateListHandle}
                        />

                    </div>
                </div>
            </div>
        </main>
    );

}

export default Users;