import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDelete } from "../../../api/DeleteApi";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import Pagination from "../../components/Pagination";
import { dotDateFormat } from "../../../util/dateFormat";
import { AxiosProductRemove } from "../../../api/UpdateApi";

const Report = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ filter, setFilter ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('report');
        } else {
            data = AxiosList('report?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    // search
    useEffect(() => {
        if(keyword) {search()}
    }, [keyword])

    //
    const searchOnChangeHandle = (e) => {
        setKeyword(e.target.value);
    }

    // 검색
    const search = () => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/report';

            const data = AxiosSearch(url, keyword);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 550);

        setTimer(callApiTimer);

    }

    // 상품 신고 해제 처리
    const productReportDelete = (idx) => {

        if(!window.confirm("정말로 해제하시겠습니까?")) return;
        
        const data = AxiosDelete('report', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 해제 되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 해제를 실패하였습니다.');
            }
        });

    }

    // 상품 강제 삭제 처리
    const productRemove = (a, b, c, d) => {

        if(window.confirm("정말로 상품을 삭제하시겠습니까?")) {

            let formData = new FormData();
            formData.append('registrationOfSale_id', a);
            formData.append('enterprise_id', b);
            formData.append('product_name', c);
            formData.append('report_content', d);

            const data = AxiosProductRemove(formData);

            data.then((res) => {
                if(res.data.success) {
                    alert('정상적으로 삭제되었습니다.');
                    window.location.reload();
                } else {
                    alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
                }
            })
        }

    }

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };

    return(
        <main id="main" className="list-set report">
            <div className="container">
                <div className="title-wrap">
                    <span className="title">신고된 상품 관리</span>
                    <div className="search">
                        <input 
                            type="text" 
                            placeholder="회사명, 상품명으로 검색" 
                            onChange={(e) => searchOnChangeHandle(e)}
                        />
                        <i className="xi-search"></i>
                    </div>
                </div>
                <div className="contents-wrap">
                    <div className="table-container">
                        <div className="table-wrap">
                            <table className="management-table">
                                <colgroup>
                                    <col width="23%" />
                                    <col width="13%" />
                                    <col width="25%" />
                                    <col width="9%"  />
                                    <col width="9%"  /> 
                                    <col width="7%"  />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>상품명</th>
                                        <th>회사명</th>
                                        <th>신고사유</th>
                                        <th>신고자</th>
                                        <th>신고일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        list ? 
                                            list.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            {item.product_name}
                                                        </td>
                                                        <td>
                                                            {item.company_name}
                                                        </td>
                                                        <td>
                                                            {item.report_content}
                                                        </td>
                                                        <td>
                                                            {item.reporter}
                                                        </td>
                                                        <td>
                                                            {item.report_date}
                                                        </td>
                                                        <td className="set-td">

                                                            <button 
                                                                className="btn-del left"
                                                                onClick={() => productReportDelete(item.id)}
                                                            >
                                                                해제
                                                            </button>

                                                            <button 
                                                                className="btn-del delete"
                                                                onClick={() => productRemove(item.registrationOfSale_id, item.enterprise_id, item.product_name, item.report_content)}
                                                            >
                                                                삭제
                                                            </button>

                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            :

                                            ''
                                    }
                                    
                                </tbody>
                            </table>
                        </div>

                        <div className="popup-container">
                            <div className="content-box box-st">
                                <h2>상품삭제</h2>
                                 <p className="content">
                                    안녕하세요 고객님 꼬모트리입니다.<br />
                                    판매등록된 <span className="content-mark">"사용하지 않는 Bearing 재고 판매합니다."</span>상품이
                                    아래의 사유로 인해 삭제되었습니다.
                                 </p>
                                 <div className="reason-box">
                                    <p className="title">상품 정보 부정확(규격, 사이즈 등)</p>
                                 </div>
                                <button className="del-btn">삭제하기</button>
                                <button className="close-btn"><i className="xi-close"></i></button>
                            </div>
                        </div>
                    </div>

                    <Pagination 
                        page={page} 
                        returnPaginateData={paginateListHandle}
                    />
                    
                </div>
            </div>
        </main>
    );

};

export default Report;