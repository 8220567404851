import React, { useState } from "react";
import styled from "styled-components";
import Menu from './Menu';
import Banner from './Banner';

const Test = () => {

    return(
        <Container>
            <Menu />
            <Banner />
            <Menu2 />
        </Container>
    );
};

export default Test;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

`;

const Menu2 = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
`;
