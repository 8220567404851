import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const Idx = atom({
    key: 'Idx',
    default: '0',
    effects_UNSTABLE: [persistAtom],
})

export default Idx;