import { LoginAxios } from "./CommonAxios"


export const UserLoginApi = async(id, pw) => {

    let formData = new FormData();

    formData.append('check_id', id);
    formData.append('password', pw);
    
    return LoginAxios(formData);

}