import React, { useState } from 'react';
import { UserLoginApi } from '../api/UserApi';
import LOGO from '../images/LOGO.png';

const Login = () => {

    const [ identified, setIdentified ] = useState('');
    const [ password,   setPassword ]   = useState('');

    const enterHandle = (e) => {
        if(e.keyCode == 13) {
            loginHandle();
        }
    }

    const loginHandle = () => {

        if(identified && password) {
            
            const data = UserLoginApi(identified, password);

            data.then((res) => {
                
                if(res.data.success) {

                    window.localStorage.setItem('commotree.access_token', res.data.data.token.access_token);
                    window.localStorage.setItem('commotree.refresh_token', res.data.data.token.refresh_token);
                    
                    window.location.href = 'main';

                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    }
                }
            })

        } else {
            alert('아이디 또는 패스워드를 입력하세요.');
        }

    }

    return (
        <div id="wrap">
            <main id="main" className="login-page">
                <div className="login-container">
                    <div className="login-box">
                        <div className="login-header">
                            <div className="logo-wrap">
                                <img 
                                    src={LOGO}
                                    alt=""
                                />
                            </div>
                            <span className="sub">
                                Please log in or sign up for an account
                            </span>
                        </div>
                        <div className="login-input-wrap">
                            <div className="input-box">
                                <span className="input-title">Username</span>
                                <input 
                                    className="id-input" 
                                    type="text" 
                                    placeholder="name@domain.com" 
                                    autoFocus defaultValue={identified}
                                    onChange={(e) => setIdentified(e.target.value)}
                                />
                            </div>
                            <div className="input-box">
                                <span className="input-title">Password</span>
                                <input 
                                    className="pw-input" 
                                    type="password" 
                                    placeholder="●●●●●●●●●●" 
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyUp={(e) => enterHandle(e)}
                                />
                            </div>
                        </div>
                        <button 
                            className="login-btn"
                            onClick={loginHandle}
                        >
                            로그인
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );

}

export default Login