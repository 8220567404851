import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AxiosDetail } from "../../../api/DetailApi";
import { AxiosEnterpriseApproval, AxiosUpdate } from "../../../api/UpdateApi";

import defaultImage from '../../../images/sample2.jpg';
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";

import { Checkbox, Input, UploadProps, Button, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UploadOutlined } from '@ant-design/icons';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { dashDateFormat } from "../../../util/dateFormat";
import { upload } from "@testing-library/user-event/dist/upload";



const DetailNotice = () => {

    const [ , setContent] = useRecoilState(Menu);
    const [ idx, ] = useRecoilState(Idx);

    const [ type, setType ]   = useState(false);   // type : false 0 일반, true 1 공지
    const [ title, setTitle ] = useState('');      // title
    const [ value, setValue ] = useState('');      // content

    const [ files, setFiles ] = useState([]);      // files : 배열(첨부파일)
    const [ removeFiles, setRemoveFiles ] = useState([]);
    
    const [ andtUploadProps, setProps ] = useState(null);

    /* 
        antd Upload 컴포넌트 Props 중 onRemove 함수 내에서 삭제된 파일 id 를 useState 를 통해
        배열 내 추가하고자 했으나, 마지막 id 만 들어오는 이슈가 있어, 하기 removeFileArrayTemp 배열을 이용하여
        처리했습니다. 
    */
    let removeFileArrayTemp = [];


    const modules = {
        toolbar: [
            //[{ 'font': [] }],
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean']
        ],
    
    };

    // 상단 고정 여부 변경
    const onChange = (e: CheckboxChangeEvent) => { 
        type ? setType(false) : setType(true);
    };


    // 문서 업데이트
    const update = () => {

        if(invalidCheck()) {
            
            const data = AxiosUpdate(idx, 'notice', createFormData());
            data.then(item => {
                
                if(item.data.success) {

                    alert("정상적으로 처리되었습니다.");

                    if(idx) {
                        window.location.reload();
                    } else {
                        setContent('Notice');
                    }
                    

                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            }).catch(error => {
                alert("일시적인 오류가 발생하여 처리할 수 없습니다.");
            })
            
        }
    };


    // 유효성 체크
    const invalidCheck = () => {

        let check = false;
        if(!title || !value) {
            alert('제목과 내용은 필수 입력입니다.');
            check = false;
        } else {
            check = true;
        }

        return check;
    }


    // request formData 생성
    const createFormData = () => {

        let formData = new FormData();

        if(idx) {
            formData.append('index',    idx);    
        }

        formData.append('type',         type ? 1 : 0);
        formData.append('title',        title);
        formData.append('content',      value);
        formData.append('write_date',   dashDateFormat());

        files.map((item, idx) => {
            if(item) {
                formData.append('files[]', item);
            }
        });

        removeFiles.map((item, idx) => {
            if(item) {
                formData.append('deleteFiles[]', item);
            }
        });

        let isChecked = false;

        isChecked = files.length > 0 || removeFiles.length > 0 ? true : false;

        formData.append('check', isChecked);

        return formData;
    }

    
    //
    const removeFilesUpdateHandle = (file) => {

        if(file.init) {       
            removeFileArrayTemp.push(file.uid);
            setRemoveFiles(removeFileArrayTemp);
        };

        const filesFilter = files.filter(files => files.status === file.uid);

        setFiles(filesFilter);

    }

    // 첨부파일 초기화
    const initProps = (files=null) => {


        const props:UploadProps = {
            
            multiple: true,
            onChange({ file, fileList }) {

                if (file.status !== 'removed') {
                    
                    let fileTempArr = [];

                    if(fileList) {
                        fileList.map((item, idx) => {
                            fileTempArr.push(item.originFileObj);
                        })
                    }

                    setFiles(fileTempArr);

                }

            },
            onRemove : function(file) {
                removeFilesUpdateHandle(file);
            },
            beforeUpload: file => {
                return false;
            },

            defaultFileList: files,

        };

        return props;

    };


    // 상세보기 API 연동, idx 데이터 유/무 기준 동작
    useEffect(() => {
        
        let res = null;
        let fileTempArr = [];

        if(idx) {
            res = AxiosDetail('notice', idx);
            res.then(item => {
                if(item.data.success) {

                    const data = item.data.data;
                    
                    setType(data.detail.type == 0 ? false : true);
                    setTitle(data.detail.title);
                    setValue(data.detail.content);

                    if(data.files) {
   
                        data.files.forEach((item) => {
                            fileTempArr.push({
                                uid : item.id,
                                name : item.file_name,
                                status : 'done',
                                url : item.file_addr,
                                percent : 100,
                                init : true,
                            })
                        })
                    }
                    setProps(initProps(fileTempArr));

                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            });
        } else {
            setProps(initProps(fileTempArr));
        }

    }, [idx])


    // cancle button onclick
    const moveToPage = () => { setContent('Notice'); };


    return(

        <main id="main" className="notice-add">
            
            <div className="container">

                <div className="title-wrap">
                    <span className="title">공지사항 관리</span>
                </div>
                
                <div className="contents-wrap">

                    <div className="add-container">
                        <Container>

                            {/*  */}
                            <Row>
                                <Col>

                                    <Description>
                                        상단고정
                                    </Description>

                                    <Checkbox 
                                        checked={type}
                                        onChange={onChange}
                                    >
                                        공지
                                    </Checkbox>

                                </Col>
                            </Row>

                            {/*  */}
                            <Row>
                                <Col>
                                    <Description>
                                        제목
                                    </Description>

                                    <Input 
                                        placeholder="제목을 입력하세요."
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Col>
                            </Row>

                            {/*  */}
                            <Row>
                                <Col>
                                    <Description>
                                        내용
                                    </Description>

                                    <ReactQuill 
                                        theme="snow" 
                                        value={value} 
                                        onChange={setValue} 
                                        modules={modules}
                                    />
                                </Col>
                            </Row>

                            {/*  */}
                            <Row>
                                <Col>
                                    <Description>
                                        첨부파일
                                    </Description>
                                    {
                                        andtUploadProps ?
                                            
                                            <Upload 
                                                {...andtUploadProps}
                                            >
                                                <Button icon={<UploadOutlined />}>
                                                    파일 업로드
                                                </Button>
                                            </Upload>

                                        :

                                        ''
                                    }

                                </Col>
                            </Row>

                            {/*  */}
                            <Row>
                                <Col>
                                    <Description>
                                        
                                    </Description>
                                    
                                    <DetailButton 
                                        type={0} 
                                        onClick={moveToPage}
                                    >
                                        취소
                                    </DetailButton>

                                    <DetailButton 
                                        type={1}
                                        onClick={update}
                                    >
                                        {idx ? '저장' : '등록'}
                                    </DetailButton>
                                </Col>
                            </Row>

                        </Container>

                    </div>

                </div>
            </div>
        </main>
    );

};

export default DetailNotice;


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    width: 100%;
    margin-bottom: 20px;


    .ant-upload-wrapper {
        width: 90%;
        min-height: 150px;
    }

    .quill {
        width: 90%;
        height: 100%;

        .ql-container {
            height: 300px;
        }
    }
`;

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    input {
        width: 90%;
        height: 40px;
        padding: 15px;
        border-radius: 2px;
        border: solid 1px #d9d9d9;
        background-color: #fff;
        box-sizing: border-box;
    }
`;

const Description = styled.div`

    width: 10%;
    padding-top: 5px;
    padding-right: 15px;
    
    display: flex;
    align-items: baseline;
    justify-content: end;

    font-size: 14px;
    font-weight: 600;

`;

const DetailButton = styled.button`

    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.type == 0 ? '#fff' : '#1890ff'};
    color: ${props => props.type == 0 ? '#000000' : '#ffffff'};
    border: solid 1px #d9d9d9;
    border-radius: 2px;
    
    margin-right: 16px;

`;