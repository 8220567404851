import { listAxios, SearchAxios } from "./CommonAxios";

export const AxiosList = (type) => {
    return listAxios(type);
}

export const AxiosSearch = (url, search = '', filter = '') => {

    let formData = new FormData();
    if(search) { formData.append('search', search); }
    if(filter) { formData.append('selectSearch', filter); }
    
    return SearchAxios(url, formData);

}