import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AxiosList } from "../../../api/ListApi";

import LOGO from '../../../images/basic-user.png';
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import { dotDateFormat } from "../../../util/dateFormat";
import { rand } from "../../../util/randomNumber";

const Talk = () => {

    const chatRoomDivRef = useRef(null);

    const headerColorList = {
        0: {bg: '#d49797', text: '#FFFFFF'},
        1: {bg: '#b23b3b', text: '#FFFFFF'},
        2: {bg: '#54b476', text: '#FFFFFF'},
        3: {bg: '#6a9ede', text: '#FFFFFF'},
        4: {bg: '#919e60', text: '#FFFFFF'},
        5: {bg: '#df9dd4', text: '#FFFFFF'},
        6: {bg: '#652e2e', text: '#FFFFFF'},
        7: {bg: '#edbc41', text: '#FFFFFF'},
        8: {bg: '#86d1db', text: '#FFFFFF'},
        9: {bg: '#5a51be', text: '#FFFFFF'},
    } 

    const [ color, setColor ] = useState(headerColorList[rand(0, 9)]);

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ filter, setFilter ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    const [ roomId, setRoomId ] = useState(null);
    const [ msg, setMsg ] = useState([]);

    const [ chatList, setChatList ] = useState([]);
    const [ user, setUser ] = useState([]);

    
    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('chatRoom');
        } else {
            data = AxiosList('chatRoom?page=' + paginateNumber);
        }
        
        data.then((list) => {

            if(list.data.success) {

                const chatRoom = list.data.data.chatRoom;
                const message = list.data.data.message;

                let userTempArray = [];
                let chatListTempArray = [];
                let userInfoTempArray = {};

                chatRoom.forEach((room, idx) => {

                    let tempRoom_id = null;

                    // get Last Message
                    let msgTempArray = null;

                    message.some((item) => {
                        if(room.id == item.chat_room_id) {

                            msgTempArray = { lastMessage : item.content ?? '', lastMessageAt : item.write_date, };
                            return msgTempArray;
                        }
                    });

                    // Create Map Data
                    chatListTempArray.push(
                        {
                            room_id       : room.id,
                            buyer         : room.type == 0 ? room.client_file_addr ? room.client_file_addr : LOGO : room.enterprise_client_file_addr ? room.enterprise_client_file_addr : LOGO,
                            buyer_name    : room.type == 0 ? room.client_name : room.enterprise_client_name,
                            seller        : room.enterprise_file_addr ? room.enterprise_file_addr : LOGO,
                            seller_name   : room.enterprise_name,
                            lastMessage   : msgTempArray?.lastMessage,
                            lastMessageAt : msgTempArray?.lastMessageAt,
                        }
                    );
                    
                    if(tempRoom_id != room.id) {
                        tempRoom_id = room.id;
                        userInfoTempArray['room_' + room.id] = chatListTempArray[idx];
                    }

                    
                });


                /* 
                list 
                    type 0 = buyer, 
                    type 1 = seller 
                */

                let msgTempArray = {};
                let initRoomId = null;

                chatListTempArray.forEach((item, idx) => {

                    let subMsgTempArray = [];

                    message.map((list) => {

                        if(item.room_id == list.chat_room_id) {

                            subMsgTempArray.push({
                                id : list.chat_room_id,
                                type : list.type,
                                content : list.content,
                                write_date : list.write_date,
                                profile_image : list.type == 0 ? item.buyer : item.seller
                            });

                        }

                    })

                    if(!initRoomId) initRoomId = item.room_id;

                    msgTempArray['room_'+item.room_id] = subMsgTempArray;
                    
                });

                setUser(userInfoTempArray);
                setChatList(chatListTempArray);
                setMsg(msgTempArray);
                setRoomId(initRoomId);
                
                /* User Profile Array
                    chatRoom : id,
                    buyer : profie Image,
                    seller : profile Image,
                    lastMessage : text,
                    lastMessageAt : at
                */

                /* Msg Array

                    chatRoom : {

                        {
                            content,
                            type = 0 seller, 1 = buyer
                        }
                        
                    }

                */

            }
        });

    }, []);

    //
    useEffect(() => {
        scrollToElement();
    }, [roomId])

    //
    const scrollToElement = () => {
        chatRoomDivRef.current.scrollTop = chatRoomDivRef.current.scrollHeight;
    };
    
    //
    const changeRoomHandle = (room_id) => {

        setRoomId(room_id);
        setColor(headerColorList[rand(0, 9)]);

    }

    //
    return (

        <main id="main" className="talk-set">

            <div className="container">

                <div className="title-wrap">
                    <span className="title">꼬모톡 내역</span>
                </div>

                <div className="contents-wrap">

                    <Container>

                        <TalkList>

                            {
                                chatList ? 
                                    chatList.map((item, idx) => {
                                        return (
                                            <TalkListItem 
                                                key={idx} 
                                                onClick={() => changeRoomHandle(item.room_id)}
                                            >

                                                <div className="profileImageCircle">
                                                    <img src={item.seller} alt="" />
                                                </div>

                                                <div className="chatInfoSection">
                                                    
                                                    <div className="chatUserName">
                                                        <p>{item.seller_name}</p>
                                                        <i className="xi-arrows-h"></i>
                                                        <p className="user-name">{item.buyer_name}</p>
                                                    </div>

                                                    <div className="chatLastMessage">
                                                        <span>{item.lastMessage}</span>
                                                    </div>

                                                    <h4>{dotDateFormat(item.lastMessageAt)}</h4>

                                                </div>

                                            </TalkListItem>
                                        )
                                    })

                                    :
                                    ''
                            }

                        </TalkList>

                        <ChatRoom>

                            <div 
                                className="chatRoomHeader" 
                                style={{background: color.bg}}
                            >

                                <div className="profileImageCircle">
                                    <img 
                                        src={user['room_' + roomId] ? user['room_' + roomId].seller : LOGO} 
                                        alt="" 
                                    />
                                </div>

                                <div 
                                    className="chatUserName"
                                >
                                    <p
                                        style={{color: color.text}}
                                    >
                                        {user['room_' + roomId] ? user['room_' + roomId].seller_name : ''}
                                    </p>
                                    <i 
                                        className="xi-arrows-h"
                                        style={{color: color.text}}
                                    >
                                    </i>
                                    <p 
                                        className="user-name"
                                        style={{color: color.text}}
                                    >
                                        {user['room_' + roomId] ? user['room_' + roomId].buyer_name : ''}
                                    </p>
                                </div>

                            </div>

                            <div 
                                className="chat"
                                ref={chatRoomDivRef}
                            >
                                {
                                    msg['room_'+roomId] ?
                                        msg['room_'+roomId].map((item, idx) => {

                                            // type = 0 buyer, 1 = seller
                                            return(
                                                <MessageBox user={item.type == 0 ? 1 : 0} key={idx}>

                                                    <div className="profileImageCircle">
                                                        <img src={item.profile_image} alt="" />
                                                    </div>

                                                    <div className="text">
                                                    
                                                        <span className="msg" dangerouslySetInnerHTML={{ __html: item.content }} >
                                                            
                                                        </span>

                                                        <span className="textDate">
                                                            {dotDateFormat(item.write_date)}
                                                        </span>

                                                    </div>

                                                </MessageBox>
                                            )
                                        })
                                        :
                                        ''

                                    }

                                {/*  */}
                                
                            </div>

                        </ChatRoom>

                    </Container>

                </div>
            </div>
        </main>

    );
};

export default Talk;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const TalkList = styled.div`

    min-width: 300px;
    min-height: 700px;
    max-height: 700px;
    margin-right: 20px;
    background-color: white;
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
`;

const TalkListItem = styled.div`
    
    padding-top: 20px;
    padding-bottom: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        cursor: pointer;
        .profileImageCircle {
            background-color: red;
            width: 60px;
            height: 60px;
        }
    }

    .profileImageCircle {

        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: thin solid #dddddddc;
        margin-right: 16px;
        
        img {
            background-position: 50% 50%;
            background-size: cover;
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }
    }

    .chatInfoSection {

        .chatUserName {

            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;

            p {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
            }

            i {
                color: gray;
                margin-left: 8px;
                margin-right: 8px;
            }
            
            
        }

        .chatLastMessage {
            span {
                display: inline-block;
                width: 180px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                color: rgba(0, 0, 0, 0.35);
            }
        }

        h4 {

            text-align: left;
            color: rgba(0, 0, 0, 0.35);
            font-size: 10px;
        }

    }
`;

const ChatRoom = styled.div`

    width: 850px;
    min-width: 500px;
    height: 700px;
    background-color: #ebebeb;
    overflow-y: auto;

    .chatRoomHeader {

        width: 100%;
        height: 104px;
        background-color: white;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 24px;

        .profileImageCircle {

            width: 56px;
            height: 56px;
            margin-right: 16px;

            img {
                background-position: 50% 50%;
                background-size: cover;
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }

        .chatUserName {

            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
            }

            i {
                color: gray;
                margin-left: 8px;
                margin-right: 8px;
            }

        }
    }


    .chat {

        background-color: #ebebeb;
        width: 100%;
        height: calc(100% - 104px);
        overflow-x: auto;
        overflow-y: auto;

    }


`;

const MessageBox = styled.div`
    
    transform: ${props => props.user == 1 ? 'scaleX(-1)' : ''};

    position: relative;

    width: 100%;
    padding: 24px;

    display: flex;
    flex-direction: row;

    .profileImageCircle {

        width: 56px;
        height: 56px;
        margin-right: 24px;
        transform: ${props => props.user == 1 ? 'scaleX(-1)' : ''};

        img {
            background-position: 50% 50%;
            background-size: cover;
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }
    }

    .text {

        width: 360px;
        position: relative;

        display: flex;
        flex-direction: row;
        
        .msg { 
            width: 240px;
            margin-top: 16px;
            width: 260px;
            padding: 20px;
            white-space: pre-wrap;
            background-color: white;
            border-radius: 12px;
            box-shadow: ${props => props.user == 1 ? '-1px 2px 5px #998787' : '1px 2px 5px #998787'};
            transform: ${props => props.user == 1 ? 'scaleX(-1)' : ''};
        }

        .textDate {
            position: absolute;
            bottom: 10px;
            right: -5px;
            font-size: 12px;
            transform: ${props => props.user == 1 ? 'scaleX(-1)' : ''};
        }
    }

`;