import React from "react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import Pagination from "../../components/Pagination";

const Transaction = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ filter, setFilter ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('sale');
        } else {
            data = AxiosList('sale?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    // search
    useEffect(() => {
        search();
    }, [keyword])

    //
    const searchOnChangeHandle = (e) => {
        setKeyword(e.target.value);
    }

    // 검색
    const search = () => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/sale';

            const data = AxiosSearch(url, keyword);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 550);

        setTimer(callApiTimer);

    }

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };

    return(
        <main id="main" className="list-set transaction-history">
            <div className="container">

                <div className="title-wrap">
                    <span className="title">거래내역 관리</span>
                    <div className="search">
                        <input 
                            type="text" 
                            placeholder="회사명, 상품명으로 검색" 
                            onChange={(e) => searchOnChangeHandle(e)}
                        />
                        <i className="xi-search"></i>
                    </div>
                </div>

                <div className="contents-wrap">
                    <div className="table-container">
                        <div className="table-wrap">
                            <table className="management-table">
                                <colgroup>
                                    <col width="20%" />
                                    <col width="12%" />
                                    <col width="15%" />
                                    <col width="10%" />
                                    <col width="10%" /> 
                                    <col width="8%"  />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>상품명</th>
                                        <th>회사명</th>
                                        <th>구매자</th>
                                        <th>구매수량</th>
                                        <th>금액</th>
                                        <th>판매등록일</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {
                                        list ? 
                                            list.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            <a href="#">
                                                                {item.product_name}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {item.company_name}
                                                        </td>
                                                        <td>
                                                            {item.client_name}
                                                        </td>
                                                        <td>
                                                            {item.buy_product_number}
                                                        </td>
                                                        <td>
                                                            {item.total_money}
                                                        </td>
                                                        <td>
                                                            {item.sale_date}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        :
                                            ''
                                    }

                                 
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <Pagination 
                        page={page} 
                        returnPaginateData={paginateListHandle}
                    />

                </div>
            </div>
        </main>
    );


};

export default Transaction;