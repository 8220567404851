import axios from 'axios';
import { API } from './VAR';

const access_token = window.localStorage.getItem('commotree.access_token') || null;


const error_res = (error) => {

    if(error.response.status == 401) {
        // refresh token check

        alert('로그인 정보가 유효하지 않습니다.');
        window.location.href = '/';
    }
    
}


/* list apis */
export const listAxios = async(type) => {

    let url = API + "list/" + type;

    return await axios.post(url, null, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        error_res(error);
    });

};


/* search apis */
export const SearchAxios = async(url, formData) => {

    return await axios.post(url, formData ?? null, {
        headers : {
            // 'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        error_res(error);
    });

}


/* detail */
export const DetailAxios = async(type, formData) => {

    let url = API + "detail/" + type;

    return await axios.post(url, formData, {
        headers: {
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        error_res(error);
    });
    
};


/* delete apis */
export const deleteAxios = async(type, id) => {

    let url = API + "delete/" + type;

    return await axios.post(url, id, {
        headers: {
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        error_res(error);
    });
};


/* update apis */
export const UpdateAxios = async(cURDType, type, formData) => {

    let url = API + cURDType  + "/" + type;

    return await axios.post(url, formData, {
        headers: {
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        error_res(error);
    });

}


/* Login */
export const LoginAxios = async(data) => {

    let url = API + "login";
    return await axios.post(url, data).catch(error => {
        console.log(error);
    })

}


/* 기업회원 승인 여부 */
export const EnterpriseApprovalAxios = async(data) => {

    let url = API + "approval/enterprise";
    return await axios.post(url, data, {
        headers: {
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        console.log(error);
    });

}


/* */
export const DeleteProduct = async(data) => {

    let url = API + "deleteProduct/report";
    return await axios.post(url, data, {
        headers: {
            'Authorization' : `Bearer ${access_token}`,
        }
    })
    .catch(error => {
        console.log(error);
    });

}