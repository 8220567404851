import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const PaginateNumber = atom({
    key: 'PageNumber',
    default: '1',
    effects_UNSTABLE: [persistAtom],
})

export default PaginateNumber;