import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDelete } from "../../../api/DeleteApi";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import Pagination from "../../components/Pagination";


const Business = () => {

    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ filter, setFilter ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('enterprise');
        } else {
            data = AxiosList('enterprise?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    // search
    useEffect(() => {
        if(keyword || filter) {search()}
    }, [filter, keyword])

    //
    const searchOnChangeHandle = (e) => {
        setKeyword(e.target.value);
    }

    //
    const detailOnClickHandle = (idx) => {
        setIdx(idx);
        setContent('DetailBusiness');
    };

    //
    const userleavedOnClickHandle = (idx) => {

        if(!window.confirm("정말로 삭제하시겠습니까?")) return;
        
        const data = AxiosDelete('enterprise', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 삭제되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
            }
        });

    }

    //
    const busginessFilterOnChage = (e) => {
        setFilter(e.target.value);
    }

    //
    const search = () => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/enterprise';

            const data = AxiosSearch(url, keyword, filter);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 550);

        setTimer(callApiTimer);

    }

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };


    return (
        <main id="main" className="member-set company">
            <header id="menu"></header>
            <div className="container">
                <div className="title-wrap">
                    <span className="title">기업 회원 관리</span>
                    <div className="right-wrap">
                        <div className="search-wrap">
                            <div 
                                className="select"
                            >
                                <select 
                                    className="businessFilter"
                                    onChange={(e) => busginessFilterOnChage(e)}
                                >
                                    <option value="전체보기">전체보기</option>
                                    <option value="승인완료">승인완료</option>
                                    <option value="승인대기">승인대기</option>
                                </select>
                            </div>
                            <div className="search">
                                <input 
                                    type="text" 
                                    placeholder="회사명, 연락처로 검색" 
                                    onChange={(e) => searchOnChangeHandle(e)}
                                />
                                <i className="xi-search"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contents-wrap">
                    <div className="table-container">
                        <div className="table-wrap">
                            <table className="management-table">
                                <colgroup>
                                    <col width="13%" />
                                    <col width="10%"  />
                                    <col width="20%"  />
                                    <col width="15%"  />
                                    <col width="15%"  /> 
                                    <col width="13%"  />
                                    <col width="7%"  />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>아이디</th>
                                        <th>회사명</th>
                                        <th>이메일</th>
                                        <th>담당자명</th>
                                        <th>담당자 연락처</th>
                                        <th>상태</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list ?
                                            list.map((user, idx) => {
                                                return(
                                                    <tr key={idx}>
                                                        <td>
                                                            {user.check_id}
                                                        </td>
                                                        <td>
                                                            {user.company_name}
                                                        </td>
                                                        <td>
                                                            {user.email}
                                                        </td>
                                                        <td>
                                                            {user.manager_name}
                                                        </td>
                                                        <td>
                                                            {user.manager_phone}
                                                        </td>
                                                        {
                                                            user.approval == 0 ?
                                                                <td className="status-td">
                                                                    <div className="cir red"></div>
                                                                    승인대기
                                                                </td>

                                                                :

                                                                <td className="status-td complete">
                                                                    <div className="cir blue"></div>
                                                                    승인완료
                                                                </td>
                                                        }
                                                        
                                                        <td className="set-td">

                                                            <a 
                                                                className="btn-del"
                                                                onClick={() => detailOnClickHandle(user.id)}
                                                            >
                                                                상세
                                                            </a>

                                                            <button 
                                                                className="btn-del"
                                                                onClick={() => userleavedOnClickHandle(user.id)}
                                                            >
                                                                탈퇴
                                                            </button>

                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            ''
                                    }
                                </tbody>
                            </table>
                        </div>

                        <Pagination 
                            page={page} 
                            returnPaginateData={paginateListHandle}
                        />

                    </div>
                </div>
            </div>
        </main>
    );

}

export default Business;