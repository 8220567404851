import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';
import Main from './pages/Main';
import Test from './pages/Test';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/" element={<Login />}></Route>
        <Route path="/main" element={<Main />}></Route>

        <Route path="/test" element={<Test />}></Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
