export function dotDateFormat(date) {

    if(!date) {
        return '';
    }

    let res = new Date(date);

    let year = Intl.DateTimeFormat('kr').format(res);
    let time = res.toTimeString().split(' ')[0];

    let timeSplit = time.split(':');

    return year + " " + timeSplit[0] + ":" + timeSplit[1];
};


export function dashDateFormat() {

    const today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let hour = today.getHours();
    let minute = today.getMinutes();
    let second = today.getSeconds();

    month = month < 10 ? '0' + month : month;
    date = minute < 10 ? '0' + date : date;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;

    const now = `${year}-${month}-${date} ${hour}:${minute}:${second}`;

    return now;

}