import React from "react";
import styled from "styled-components";

const Banner = () => {
    return(
        <Container>
            test
        </Container>
    )
};

export default Banner;

const Container = styled.div`
    width  : calc(100% - 264px);
    height: 100%;
    background-color: #d5e49d;

    display: flex;
    justify-content: center;
    align-items: center;
`;