import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDetail } from "../../../api/DetailApi";
import { AxiosUpdate } from "../../../api/UpdateApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";


const DetailBanner = () => {

    const [ , setContent] = useRecoilState(Menu);
    const [ idx, ] = useRecoilState(Idx);

    const [ title, setTitle ] = useState('');
    const [ link, setLink ] = useState('');
    const [ pcImageName, setPcImageName ] = useState('');
    const [ mbImageName, setMbImageName ] = useState(''); 
    const [ pcImageFile, setPcImageFile ] = useState(null);
    const [ mbImageFile, setMbImageFile ] = useState(null);

    useEffect(() => {

        let res = null;
        if(idx) {
            res = AxiosDetail('banner', idx);
            res.then(item => {
                if(item.data.success) {
                    const data = item.data.data;
                    setTitle(data.detail.title);
                    setLink(data.detail.link);
                    setPcImageName(data.pcImage.file_name);
                    setMbImageName(data.mobile.file_name);
                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            })
        }

    }, [idx]);


    // save and update handle
    const saveOnClickHandle = () => {

        let formData = new FormData();

        formData.append('title', title);
        formData.append('link', link);

        // register
        if(!idx) {

            formData.append('pcImageFile', pcImageFile);
            formData.append('mobileImageFile', mbImageFile);

        // update
        } else {

            formData.append('index', idx);

            // pc, mobile 개별 업데이트 현황에 따라 true, false 처리한다.
            if(pcImageFile) {
                formData.append('updatePcImage', Boolean(true));
                formData.append('pcImageFile', pcImageFile);
            } else {
                formData.append('updatePcImage', Boolean(false));
            }
            
            if(mbImageFile) {
                formData.append('updateMobileImage', Boolean(true));
                formData.append('mobileImageFile', mbImageFile);

            } else {
                formData.append('updateMobileImage', Boolean(false));
            }
        }

        const data = AxiosUpdate(idx, 'banner', formData);
        data.then(item => {

            if(item.data.success) {

                alert("정상적으로 처리되었습니다.");
                if(idx) {
                    window.location.reload();
                } else {
                    setContent('Banners');
                }
                

            } else {
                if(item.data.msg) {
                    alert(item.data.msg);
                }
            }
        }).catch(error => {
            alert("일시적인 오류가 발생하여 처리할 수 없습니다.");
        })
        
    };


    // file onchange
    const fileOnChangeHandle = (e, type) => {
        
        e.preventDefault();

        if(type == 0) {
            setPcImageFile(e.target.files[0]);
            setPcImageName(e.target.files[0].name);
        } else {
            setMbImageFile(e.target.files[0])
            setMbImageName(e.target.files[0].name);
        }
    }

    return (

        <main id="main" className="banner-add">

            <div className="container">

                <div className="title-wrap">
                    <span className="title">배너 관리</span>
                </div>

                <div className="contents-wrap">

                    <div className="add-container">

                        <div className="add-box">
                            <span className="title">배너제목</span>
                            <div className="input-wrap">
                                <input 
                                    type="text" 
                                    placeholder="제목을 입력하세요" 
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="add-box">
                            <span className="title">베너 링크</span>
                            <div className="input-wrap">
                                <input 
                                    type="text" 
                                    placeholder="링크를 입력하세요(https:// 포함)" 
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="add-box upload f1">
                            <span className="title">PC 이미지</span>

                            <div className="file-find">

                                <div className="file-upload">

                                    <label className="btn-style-find" htmlFor="as_pc_file">
                                        <i className="xi-upload"></i>
                                        <span>파일 업로드</span>
                                    </label>

                                </div>

                            </div> 
                        </div>

                        <div className="add-box list-box">

                            <input type="file" id="as_pc_file" onChange={(e) => fileOnChangeHandle(e, 0)}/>

                            <ul className="asfile_ul">

                                <li>
                                    <div className="file_name">
                                        <span>{pcImageName}</span>
                                    </div>
                                </li>

                            </ul>

                        </div>

                        <div className="add-box upload f2">

                            <span className="title">Mobile 이미지</span>

                            <div className="file-find">

                                <div className="file-upload">
                                    
                                    <label className="btn-style-find" htmlFor="as_mb_file">
                                        <i className="xi-upload"></i>
                                        <span>파일 업로드</span>
                                    </label>

                                </div>
                            </div> 

                        </div>

                        <div className="add-box list-box">

                            <input type="file" id="as_mb_file" onChange={(e) => fileOnChangeHandle(e, 1)}/>

                            <ul className="asfile_ul">
                                <li>
                                    <div className="file_name">
                                        <span>{mbImageName}</span>
                                    </div>
                                </li>
                            </ul>

                        </div>

                        <div className="btn-wrap">

                            <button 
                                className="cancle-btn"
                                onClick={() => setContent('Banners')}
                            >
                                취소
                            </button>
                            <button
                                className="save-btn"
                                onClick={saveOnClickHandle}
                            >
                                저장
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </main>
    );


};

export default DetailBanner;