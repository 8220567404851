import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AxiosDelete } from "../../../api/DeleteApi";
import { AxiosList, AxiosSearch } from "../../../api/ListApi";
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";
import PaginateNumber from "../../../recoil/PaginateNumber";
import Pagination from "../../components/Pagination";

const Notice = () => {

    
    const [ list, setList ] = useState([]);
    const [ page, setPage ] = useState([]);    

    const [ , setContent] = useRecoilState(Menu);
    const [ , setIdx] = useRecoilState(Idx);
    const [ paginateNumber, ] = useRecoilState(PaginateNumber);

    const [ timer, setTimer ] = useState(null);
    const [ keyword, setKeyword ] = useState('');

    //
    useEffect(() => {

        let data = null;

        // api
        if(!paginateNumber) {
            data = AxiosList('notice');
        } else {
            data = AxiosList('notice?page=' + paginateNumber);
        }
        
        data.then((list) => {
            if(list.data.success) {
                setList(list.data.data.data);
                setPage(list.data.data.links);
            }
        })

    }, [])

    // search
    useEffect(() => {
        search()
    }, [keyword])

    //
    const searchOnChangeHandle = (e) => {
        setKeyword(e.target.value);
    }

    //
    const detailOnClickHandle = (idx) => {
        setIdx(idx);
        setContent('DetailNotice');
    };

    //
    const deleteOnClickHandle = (idx) => {

        if(!window.confirm("정말로 삭제하시겠습니까?")) return;
        
        const data = AxiosDelete('notice', idx);

        data.then((res) => {
            if(res.data.success) {
                alert('정상적으로 삭제되었습니다.');
                window.location.reload();
            } else {
                alert('일시적인 오류로 인해 삭제를 실패하였습니다.');
            }
        });

    }

    //
    const search = () => {

        clearTimeout(timer);

        const callApiTimer = setTimeout(() => {

            const url = 'https://api.commotree.com/api/search/notice';

            const data = AxiosSearch(url, keyword);

            data.then(item => {
                setList(item.data.data.data);
                setPage(item.data.data.links);
            })

        }, 550);

        setTimer(callApiTimer);

    }

    //
    const register = () => {
        setIdx(null);
        setContent('DetailNotice');
    };

    //
    const paginateListHandle = (data) => {
        setList(data.data.data.data);
        setPage(data.data.data.links);
    };

    return(
        <main id="main" className="list-set notice-set">
            <div className="container">
                <div className="title-wrap">
                    <span className="title">공지사항 관리</span>
                    <div className="right-wrap">
                        <div className="search-wrap">
                            <div className="search">
                                <input 
                                    type="text" 
                                    placeholder="제목으로 검색"
                                />
                                <i className="xi-search"></i>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="contents-wrap">

                    <div className="table-container">

                        <RegisterButton
                            onClick={register}
                        >
                            등록하기
                        </RegisterButton>                        

                        <div className="table-wrap">
                            <table className="management-table">
                                <colgroup>
                                    <col width="10%" />
                                    <col width="50%" />
                                    <col width="10%" />
                                    <col width="7%"  />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>공지</th>
                                        <th>제목</th>
                                        <th>작성일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list ? 
                                            list.map((item, idx) => {
                                                return(
                                                    <tr key={idx}>
                                                        <td>
                                                            {item.type == 0 ? '일반' : '공지'}
                                                        </td>
                                                        <td>
                                                            {item.title}
                                                        </td>
                                                        <td>
                                                            {item.write_date}
                                                        </td>
                                                        <td className="set-td">
                                                            <a 
                                                                className="btn-del"
                                                                onClick={() => detailOnClickHandle(item.id)}
                                                            >
                                                                상세
                                                            </a>
                                                            <button
                                                                className="btn-del"
                                                                onClick={() => deleteOnClickHandle(item.id)}
                                                            >
                                                                삭제
                                                            </button>
                                                        </td>
                                                    </tr>   
                                                );
                                            })
                                        :
                                        ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*  */}
                    <Pagination 
                        page={page} 
                        returnPaginateData={paginateListHandle}
                    />

                </div>
            </div>
        </main>
    );
};

export default Notice;

const RegisterButton = styled.button`

    margin: 10px;
    margin-bottom: 25px;

    width: 82px;
    height: 32px;
    background-color: #1890ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    font-size: 14px;
    font-weight: normal;
    color: #fff;

`;