import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import Menu from "../recoil/Menu";

import Header from "./components/Header";
import DetailBanner from "./templates/detail/DetailBanner";
import DetailUser from "./templates/detail/DetailUser";
import DetailBusiness from "./templates/detail/DetailBusiness";

import Banner from "./templates/list/Banner";
import Business from "./templates/list/Business";
import Users from "./templates/list/Users";
import Leaved from "./templates/list/Leaved";
import Talk from "./templates/list/Talk";
import Report from "./templates/list/Report";
import Transaction from "./templates/list/Transaction";
import Qna from "./templates/list/Qna";
import DetailQna from "./templates/detail/DetailQna";
import Notice from "./templates/list/Notice";
import DetailNotice from "./templates/detail/DetailNotice";

const Main = () => {

    const [ menu, __ ] = useRecoilState(Menu); 

    useEffect(() => {}, [menu])

    const ContentComponents = {

        Banners     : <Banner />,
        Users       : <Users />,
        Business    : <Business />,
        Leaved      : <Leaved />,
        Talk        : <Talk />,
        Report      : <Report />,
        Transaction : <Transaction />,
        Qna         : <Qna />,
        Notice      : <Notice />,
        
        DetailBanner    :   <DetailBanner />,
        DetailUser      :   <DetailUser />,
        DetailBusiness  :   <DetailBusiness />,
        DetailQna       :   <DetailQna />,
        DetailNotice    :   <DetailNotice />,

    };


    return (

        <Container>
            <Header />
            {
                ContentComponents[menu]
            }
        </Container>

    )

}

export default Main;


const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;
