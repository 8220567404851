import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDetail } from "../../../api/DetailApi";
import { AxiosEnterpriseApproval } from "../../../api/UpdateApi";

import defaultImage from '../../../images/basic-user.png';
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";

const DetailBusiness = () => {

    const [ , setContent] = useRecoilState(Menu);
    const [ idx, ] = useRecoilState(Idx);

    const [ id,             setId ]                 = useState('');
    const [ checkId,        setCheckId ]            = useState('');
    const [ companyName,    setCompanyName ]        = useState('');
    const [ companyNumber,  setCompanyNumber ]      = useState('');
    const [ email,          setEmail ]              = useState('');
    const [ managerName,    setManagerName ]        = useState('');
    const [ managerPhone,   setManagerPhone ]       = useState('');
    const [ representative, setRepresentative ]     = useState('');
    const [ webAddress,     setWebAddress ]         = useState('');
    const [ approval,       setApproval ]           = useState(false);
    const [ agree,          setAgree ]              = useState('');
    const [ address,        setAddress ]            = useState('');
    const [ addressDetail,  setAddressDetail ]      = useState('');
    const [ addressZipCode, setAddressZipCode ]     = useState('');
    const [ file,           setFile ]               = useState(null);
    const [ fileName,       setFileName ]           = useState('');
    const [ fileImageName,  setFileImageName ]      = useState('');
    const [ profile,        setProfile ]            = useState('');
    
    //
    useEffect(() => {

        let res = null;
        if(idx) {
            
            res = AxiosDetail('enterprise', idx);
            res.then(item => {
                if(item.data.success) {

                    const data = item.data.data;
                    setId(data.detail.id);
                    setCheckId(data.detail.check_id);
                    setEmail(data.detail.email);
                    setCompanyName(data.detail.company_name);
                    setCompanyNumber(data.detail.company_registration_number);
                    setManagerName(data.detail.manager_name);
                    setManagerPhone(data.detail.manager_phone);
                    setRepresentative(data.detail.representative);
                    setWebAddress(data.detail.web_address);
                    setApproval(data.detail.approval == 0 ? false : true);
                    setAgree(data.detail.agree);
                    setAddress(data.detail.address);
                    setAddressDetail(data.detail.address_detail);
                    setAddressZipCode(data.detail.address_zipcode);
                    setFileName(data.file.file_name);
                    setFile(data.file.file_addr);
                    setProfile(data.profile.file_addr);

                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            })
        }

    }, [idx]);

    //
    const approvalOnChangeHandle = (e) => {

        const checked = e.target.checked;
        setApproval(checked);
        AxiosEnterpriseApproval(id, checked);

    }

    return(
        <main id="main" className="member-detail company">
            <div className="container">

                <div className="title-wrap">
                    <span className="title">기업 회원 관리</span>
                </div>

                <div className="contents-wrap">
                    <div className="add-container">
                        <div className="user-container">

                            <div className="img-wrap">
                                <img src={profile ? profile : defaultImage} alt="" />
                            </div>

                            <div className="toggle-wrap">
                                
                                {
                                    approval ?
                                        <div className="add-box status-text">
                                            <input 
                                                type="checkbox" 
                                                id="toggle" 
                                                checked={true}
                                                onChange={(e) => approvalOnChangeHandle(e)}
                                                hidden 
                                            />  
                                            <label 
                                                htmlFor="toggle" 
                                                className="toggleSwitch" 
                                            >
                                                <span className="toggleButton"></span>   
                                            </label>
                                            <span className="title">{approval ? "승인완료" : "승인대기"}</span>
                                        </div>
                                    :
                                        <div className="add-box status-text">
                                            <input 
                                                type="checkbox" 
                                                id="toggle" 
                                                checked={false}
                                                onChange={(e) => approvalOnChangeHandle(e)}
                                                hidden 
                                            />
                                            <label 
                                                htmlFor="toggle" 
                                                className="toggleSwitch" 
                                            >
                                                <span className="toggleButton"></span>   
                                            </label>
                                            <span className="title">{approval ? "승인완료" : "승인대기"}</span>
                                        </div>
                                }
                                     
                           </div>

                        </div>
                        <div className="input-container">
                            <div className="left-box">
                            <ul className="form-wrap">
                                <li>
                                    <p className="form-title">
                                        회사명
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={companyName} 
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        이메일
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={email}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        사업자등록번호
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={companyNumber}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        대표자명
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={representative}
                                        />
                                    </div>
                                </li>
                                <li className="reposition">
                                    <p className="form-title address-title">
                                        회사주소
                                    </p>
                                    <div className="address-wrap">
                                        <div className="input-wrap input-resize">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                id="name" 
                                                placeholder="우편번호" 
                                                defaultValue={addressZipCode}
                                            />
                                        </div>
                                        <div className="input-wrap">
                                            <input 
                                                type="text" 
                                                name="address" 
                                                id="address" 
                                                placeholder="도로명 주소" 
                                                defaultValue={address}
                                            />
                                        </div>
                                        <div className="input-wrap">
                                            <input 
                                                type="text" 
                                                name="detail-address" 
                                                id="detail-address" 
                                                placeholder="상세 주소" 
                                                defaultValue={addressDetail}
                                            />
                                        </div>
                                </div>
                                </li>
                            </ul>

                            </div>
                            <div className="right-box">
                            <ul className="form-wrap">
                                <li>
                                    <p className="form-title">
                                        아이디
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={checkId}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        홈페이지
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={webAddress}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        사업자등록증
                                    </p>
                                    <div className="file_name">
                                        <div className="left-info">
                                            <span className="name">{fileName}</span>
                                        </div>
                                        <div className="right-info">
                                            <a href={file} download>
                                                <button>
                                                    다운로드
                                                    <i className="xi-download"></i>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        담당자명
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={managerName}
                                        />
                                    </div>
                                </li>
                                <li className="resize">
                                    <p className="form-title">
                                        담당자 연락처
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={managerPhone}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        약관동의
                                    </p>
                                    <div className="input-wrap check-box" style={{border : 'none'}}>
                                        <input 
                                            type="checkbox" 
                                            checked={agree == 0 ? true : false}
                                            // defaultChecked={agree == 0 ? true : false}
                                            readOnly
                                        />
                                    </div>
                                    <span className="agree-text">광고 및 마케팅 활용 동의</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="btn-wrap">
                            <button 
                                className="save-btn"
                                onClick={() => setContent('Business')}
                            >
                                확인
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default DetailBusiness;