import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AxiosDetail } from "../../../api/DetailApi";

import defaultImage from '../../../images/basic-user.png';
import Idx from "../../../recoil/Idx";
import Menu from "../../../recoil/Menu";

const DetailUser = () => {

    const [ , setContent] = useRecoilState(Menu);
    const [ idx, ] = useRecoilState(Idx);

    const [ checkId, setCheckId ]             = useState('');
    const [ name, setName ]                   = useState('');
    const [ nickName, setNickName ]           = useState('');
    const [ email, setEmail ]                 = useState('');
    const [ birth, setBirth ]                 = useState('');
    const [ agree, setAgree ]                 = useState('');
    const [ address, setAddress ]             = useState('');
    const [ addressDetail, setAddressDetail ] = useState('');
    const [ zipCode, setZipCode ]             = useState('');
    const [ phone, setPhone ]                 = useState('');
    const [ profile, setProfile ]             = useState('');
    

    useEffect(() => {

        let res = null;
        if(idx) {
            
            res = AxiosDetail('client', idx);
            res.then(item => {
                if(item.data.success) {

                    const data = item.data.data;
                    setCheckId(data.user.check_id);
                    setName(data.user.name);
                    setNickName(data.user.nickname);
                    setEmail(data.user.email);
                    setBirth(data.user.birth);
                    setAgree(data.user.agree);
                    setAddress(data.user.address);
                    setAddressDetail(data.user.address_detail);
                    setZipCode(data.user.address_zipcode);
                    setPhone(data.user.phone);
                    if(data.profile) {
                        if(data.profile.file_addr) {
                            setProfile(data.profile.file_addr);
                        }
                    }

                } else {
                    if(item.data.msg) {
                        alert(item.data.msg);
                    }
                }
            })
        }

    }, [idx]);


    return(
        
        <main id="main" className="member-detail individual">
            <header id="menu"></header>
            <div className="container">
                <div className="title-wrap">
                    <span className="title">개인 회원 관리</span>
                </div>
                <div className="contents-wrap">
                    <div className="add-container">
                        <div className="user-container">
                            <div className="img-wrap">
                                <img src={profile ? profile : defaultImage} alt="" />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="left-box">
                            <ul className="form-wrap">
                                <li>
                                    <p className="form-title">
                                        닉네임
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        placeholder="" 
                                        defaultValue={nickName}
                                    />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        연락처
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        placeholder="" 
                                        defaultValue={phone}
                                    />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        생년월일
                                    </p>
                                    <div className="input-wrap input-resize">
                                        <input 
                                            type="date" 
                                            id="" 
                                            defaultValue={birth}
                                        />
                                    </div>
                                </li>
                                <li className="reposition">
                                    <p className="form-title address-title">
                                        주소
                                    </p>
                                    <div className="address-wrap">
                                        <div className="input-wrap input-resize">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                id="name" 
                                                placeholder="우편번호" 
                                                defaultValue={zipCode}
                                            />
                                        </div>
                                        <div className="input-wrap">
                                            <input 
                                                type="text" 
                                                name="address" 
                                                id="address" 
                                                placeholder="도로명 주소" 
                                                defaultValue={address}
                                            />
                                        </div>
                                        <div className="input-wrap">
                                            <input 
                                                type="text" 
                                                name="detail-address" 
                                                id="detail-address" 
                                                placeholder="상세 주소" 
                                                defaultValue={addressDetail}
                                            />
                                        </div>
                                </div>
                                </li>
                            </ul>

                            </div>
                            <div className="right-box">
                            <ul className="form-wrap">
                                <li>
                                    <p className="form-title">
                                        아이디
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder=""
                                            defaultValue={checkId}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        이름(실명)
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={name}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        이메일
                                    </p>
                                    <div className="input-wrap">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="" 
                                            defaultValue={email}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <p className="form-title">
                                        약관동의
                                    </p>
                                    <div className="input-wrap check-box" style={{border : 'none'}}>
                                        <input 
                                            type="checkbox" 
                                            checked={agree == 0 ? true : false}
                                            // defaultChecked={agree == 0 ? true : false}
                                            readOnly
                                        />
                                    </div>
                                    <span className="agree-text">광고 및 마케팅 활용 동의</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="btn-wrap">
                            <button 
                                className="save-btn"
                                onClick={() => setContent('Users')}
                            >
                                확인
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default DetailUser;