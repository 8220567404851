import { DeleteProduct, EnterpriseApprovalAxios, UpdateAxios } from "./CommonAxios";

 
export const AxiosUpdate = (id=null, type, formData) => {

    let cURDType = id ? 'update' : 'register';

    return UpdateAxios(cURDType, type, formData);

}

// 기업회원 가입 승인 여부
export const AxiosEnterpriseApproval = (id, type) => {

    let formData = new FormData();
    formData.append('index', id);
    formData.append('checkApproval', Boolean(type));

    return EnterpriseApprovalAxios(formData);

}

//
export const AxiosProductRemove = (formData) => {

    return DeleteProduct(formData);

}