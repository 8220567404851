import React from "react";
import styled from "styled-components";
import LOGO from '../images/LOGO.png';

const Title = () => {

    return(
        <Container>
            <img src="https://api.kjeonse.com/storage/popup/1675048130_open-popup.jpg" alt="" />
        </Container>
    );
};

export default Title;

const Container = styled.div`
    width: 264px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.16);
`;