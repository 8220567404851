import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AxiosList, AxiosSearch } from "../../api/ListApi";
import PaginateNumber from "../../recoil/PaginateNumber";


const Pagination = ({
    page, 
    keyword = '',
    returnPaginateData
}) => {

    // 현재 클릭한 페이지를 다시금 눌러서 이동할 수 없도록 한다.
    let pageNumber = 0;

    const [ , setPageNumber ] = useRecoilState(PaginateNumber);

    const paginateOnClickHandle = (url, page) => {

        if(!url || page == pageNumber) return;

        setPageNumber(page);

        const data = AxiosSearch(url, keyword);

        data.then(list => {
            returnPaginateData(list);
        })
        
    }

    return (
        <Container>

            <div className="pagenation-wrap">

                <ul className="page-nav">

                    {   
                        page.map((list, idx) => {

                            if(list.label.includes("Previous")) {

                                return (
                                    <li key={idx}>
                                        <a onClick={() => paginateOnClickHandle(list.url, idx)}>
                                            <i className="xi-angle-left" />
                                        </a>
                                    </li>
                                );

                            } else if (list.label.includes("Next")) {

                                return (
                                    <li key={idx}>
                                        <a 
                                            onClick={() => paginateOnClickHandle(list.url, idx)}
                                        >
                                            <i className="xi-angle-right" />
                                        </a>
                                    </li>
                                );

                            } else { 

                                if(list.active == true) { 
                                    pageNumber = idx;
                                }

                                return (
                                    <li key={idx}>
                                        <a 
                                            onClick={() => paginateOnClickHandle(list.url, idx)}
                                            className={list.active ? "now" : ""}
                                        >
                                            {idx}
                                        </a>
                                    </li>
                                );

                            }

                        })
                    }

                </ul>

            </div>

        </Container>
    );
}

export default Pagination;


const Container = styled.div`
    width: 100%;
    height: 100%;
`;